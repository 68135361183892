// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/i18n.ts"
);
import.meta.hot.lastModified = "1731508025764.3542";
}
// REMIX HMR END

export default {
  supportedLngs: ['en-GB'],
  fallbackLng: 'en-GB',
  defaultNS: 'translation',
  react: { useSuspense: false }
}
